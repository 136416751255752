import "ag-grid-community"
import {
  ColDef,
  ColumnEvent,
  ColumnState,
  FilterChangedEvent,
  FilterModel,
  FirstDataRenderedEvent,
  GridReadyEvent,
  IRowNode,
  IsRowSelectable,
  PaginationChangedEvent,
  RefreshServerSideParams,
  RowModelType,
  SelectionChangedEvent,
  SideBarDef,
  SizeColumnsToContentStrategy,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy,
} from "ag-grid-community"
import { AgGridReact } from "ag-grid-react"
import clsx from "clsx"
import { ENDivider } from "en-react/dist/src/components/Divider"
import { debounce } from "lodash"
import {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react"
import { useAppAbility } from "src/casl/ability"
import ActivityLogsTimeFilter from "src/pages/ActivityLogs/ActivityLogsTimeFilter"
import { IconNameType } from "src/shared/components/Icons"
import { ChartsTimeIntervalFilterType } from "src/utils"
import { BLOCK_LOAD_DEBOUNCE_MILLISECONDS, DATA_GRID_POLLING_INTERVAL, MAX_CACHE_BLOCKS } from "src/utils/constants"
import { useDataGridStyles } from "./DataGrid.styles"
import { BlankSlateProps, DataGridBlankSlate } from "./DataGridBlankSlate"
import { DataGridLoader } from "./DataGridLoader"
import DataGridLoadingComponent from "./DataGridLoadingComponent"
import DataGridNavigationBar from "./DataGridNavigationBar/DataGridNavigationBar"
import DataGridPopover from "./DataGridPopOver"

export const errorLoadingOverlayParams: BlankSlateProps = {
  iconName: "enWarningCircle" as IconNameType,
  slateHeading: "Error Loading Records",
  text: "Refresh to try again.",
}

const defaultNoRowsOverlayComponentParams = {
  iconName: "blankSlate" as IconNameType,
  slateHeading: "No Records",
}

export interface PopoverButtonType {
  name: string
  title?: string | ((rowData: any) => string)
  callback?: (name: string, rowData: any) => void
  disabled?: (rowData: any) => boolean
  variant?: "normal" | "separator" | "remove" | ((rowData: any) => "normal")
  show?: (rowData: any) => boolean
  hide?: (rowData: any) => boolean
  tooltipText?: string
  disabledTooltipText?: string | ((rowData: any) => string)
  disabledTooltipTextPlacement?: "bottom" | "left" | "right" | "top"
  emptySlateElement?: JSX.Element
}

interface GridStorageType {
  columnState: ColumnState[] | null
  columnGroupState:
    | {
        groupId: string
        open: boolean
      }[]
    | null
  filterModel: FilterModel | null
}

type DataGridProps = {
  label?: string
  onSearchCallBack?: (searchText: string) => void
  columnDefs: ColDef[]
  rowData?: any
  treeData?: boolean
  serverSideGroupSelect?: string
  serverSideGroupKey?: string | number
  autoGroupColumnDef?: ColDef
  onGridReady: (event: GridReadyEvent<any>) => void
  isExpandable?: boolean
  onRowExpanded?: (expandedArray: string[], data?: any) => void //expandedArray: Array of expanded row ids, data: returns the data of the current expanded row
  isSortable?: boolean
  expandableRowComponent?: (params: any) => JSX.Element
  detailRowAutoHeight?: boolean
  suppressContextMenu?: boolean
  isMultiSelect?: boolean
  showRowsSelected?: boolean
  onRowSelected?: (selectedRows: any) => void
  onRowDragEnd?: (event: any) => void
  onRowDragEnter?: (event: any) => void
  rowDragEntireRow?: boolean
  paginationAutoPageSize?: boolean
  pagination?: boolean
  showLoading?: boolean
  noRowsOverlayComponentParams?: BlankSlateProps
  numberOfRowsSelected?: number
  onActionCallBack?: () => void
  actionText?: string
  onDragUpCallBack?: () => void
  dragUpText?: string
  onSaveCallBack?: () => void
  saveText?: string
  onDragDownCallBack?: () => void
  dragDownText?: string
  cacheBlockSize?: number
  onBulkActionPopover?: {
    popOverList: PopoverButtonType[]
  }
  isRowSelectable?: IsRowSelectable<any>
  isSideBarHidden?: boolean
  containerHeight?: number // For Data Grid inside expanded view
  agContainerClassName?: string
  showNavbars?: boolean
  onRefreshCallBack?: () => void
  refreshInterval?: number
  hasFilters?: boolean
  applyCollapsibleWrapper?: boolean
  hasColumns?: boolean
  dynamicWidthAutoSize?: boolean
  className?: string
  getRowHeight?: (params: any) => number
  handleTimeFilter?: (event: any) => void
  timeFilterValue?: ChartsTimeIntervalFilterType
  setPageSize?: (size: number, pageNumber?: number) => void
  setOffSet?: (offset: number) => void
  rowModelType?: RowModelType
  setAllSelected?: React.Dispatch<React.SetStateAction<boolean>>
  sortingOrder?: ("asc" | "desc" | null)[]
  rowHeight?: number
  popOverList?: PopoverButtonType[]
  viewPartialPopOverList?: boolean
  disableTimeFilter?: boolean
  groupByCallBack?: {
    options: { [key: string]: string }[]
    onChange?: (selectedGroups: { [key: string]: string }) => void
    configValue?: string
  }
  headerHeight?: number
  rowDragMultiRow?: boolean
  rowDragManaged?: boolean
  onFilterChanged?: (event: FilterChangedEvent) => void
  autoSizeStrategy?: SizeColumnsToFitGridStrategy | SizeColumnsToFitProvidedWidthStrategy | SizeColumnsToContentStrategy
  isDisabledPopOverList?: (data?: any) => boolean
  disabledPopOverTooltip?: (data?: any) => string
  maxBlocksInCache?: number
  blockLoadDebounceMillis?: number
}

const DataGrid: ForwardRefRenderFunction<AgGridReact, DataGridProps> = (props, ref) => {
  const {
    label = "Rows",
    onSearchCallBack,
    detailRowAutoHeight = true,
    suppressContextMenu = true,
    isExpandable = false,
    onRowExpanded,
    isSortable = true,
    onGridReady,
    columnDefs,
    rowData,
    treeData = false,
    serverSideGroupSelect,
    serverSideGroupKey,
    autoGroupColumnDef,
    expandableRowComponent,
    isMultiSelect = false,
    showRowsSelected = true,
    onRowSelected,
    paginationAutoPageSize,
    pagination = false,
    showLoading,
    noRowsOverlayComponentParams = defaultNoRowsOverlayComponentParams,
    numberOfRowsSelected,
    onActionCallBack,
    actionText = "",
    refreshInterval = DATA_GRID_POLLING_INTERVAL,
    disableTimeFilter = false,
    onRowDragEnd,
    onRowDragEnter,
    rowDragEntireRow = false,
    onDragUpCallBack,
    dragUpText = "",
    onSaveCallBack,
    saveText = "",
    onDragDownCallBack,
    dragDownText = "",
    onBulkActionPopover,
    isRowSelectable,
    isSideBarHidden = false,
    containerHeight, // For Data Grid inside expanded view
    agContainerClassName,
    showNavbars = false,
    onRefreshCallBack,
    hasFilters = true,
    applyCollapsibleWrapper = false,
    hasColumns = false,
    className = "",
    getRowHeight,
    handleTimeFilter,
    timeFilterValue,
    setPageSize,
    setOffSet,
    rowModelType = "clientSide",
    setAllSelected,
    sortingOrder = ["asc", "desc", null],
    cacheBlockSize = 10,
    rowHeight,
    popOverList,
    viewPartialPopOverList,
    groupByCallBack,
    headerHeight,
    rowDragMultiRow = false,
    rowDragManaged = false,
    onFilterChanged,
    autoSizeStrategy,
    isDisabledPopOverList,
    disabledPopOverTooltip,
    maxBlocksInCache = isMultiSelect ? undefined : MAX_CACHE_BLOCKS,
    blockLoadDebounceMillis = BLOCK_LOAD_DEBOUNCE_MILLISECONDS,
  } = props

  const canViewCompletePopOverList = useAppAbility().can("view", "completePopoverMenu")
  const canViewPartialPopOverList = useAppAbility().can("view", "partialPopoverMenu") && viewPartialPopOverList

  const popOverMenu = useCallback(() => {
    return canViewCompletePopOverList || canViewPartialPopOverList ? popOverList : undefined
  }, [])

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      sortable: isSortable,
      resizable: true,
      suppressMovable: true,
      suppressMenu: true,
      minWidth: 200,
      cellClass: "ag-text-cell",
      suppressHeaderFilterButton: true,
    }
  }, [isSortable])

  const [serverSideRefreshing, setServerSideRefreshing] = useState(false)
  const [polling, setPolling] = useState(false)

  const pollTimerRef = useRef<NodeJS.Timeout>()

  const gridRef = useRef<AgGridReact>(null)

  useImperativeHandle(ref, () => {
    if (gridRef.current?.api) {
      const newObject = Object.create(Object.getPrototypeOf(gridRef.current.api))

      return {
        ...gridRef.current,
        api: Object.assign(newObject || {}, {
          ...gridRef.current.api,
          refreshServerSide: (params?: RefreshServerSideParams | undefined) => {
            setServerSideRefreshing(true)
            gridRef.current?.api.refreshServerSide(params)
          },
        }),
      } as AgGridReact
    } else {
      return gridRef.current as AgGridReact
    }
  })
  const classes = useDataGridStyles()
  const [expandedRow, setExpandedRow] = useState<string | null>(null)
  const [expandedRows, setExpandedRows] = useState<string[]>([])
  const onSelectionChanged = useCallback((e: SelectionChangedEvent<any>) => {
    if (setAllSelected) {
      if (e.source === "uiSelectAll") {
        setAllSelected?.((prev) => {
          if (prev === false) {
            const nodesToSelect: IRowNode[] = []
            gridRef.current!.api.forEachNode((node) => nodesToSelect.push(node))
            gridRef.current!.api.setNodesSelected({ nodes: nodesToSelect, newValue: true })
            onRowSelected?.(gridRef.current!.api.getSelectedRows())
            return !prev
          } else {
            const nodesToSelect: IRowNode[] = []
            gridRef.current!.api.forEachNode((node) => nodesToSelect.push(node))
            gridRef.current!.api.setNodesSelected({ nodes: nodesToSelect, newValue: false })
            onRowSelected?.(gridRef.current!.api.getSelectedRows())
            return !prev
          }
        })
      } else {
        if (e.source === "checkboxSelected") {
          setAllSelected?.((prev) => {
            if (prev) return !prev
            else return prev
          })
        }
        const selectedRows = gridRef.current!.api.getSelectedRows()
        return onRowSelected?.(selectedRows)
      }
    } else {
      const selectedRows = gridRef.current!.api.getSelectedRows()
      return onRowSelected?.(selectedRows)
    }
  }, [])

  const handleKeydown = useCallback((e: KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault()
      e.stopImmediatePropagation()
    }
  }, [])

  const addEventListeners = useCallback(() => {
    const filterInputWrappers = document.querySelectorAll(".ag-text-field-input-wrapper")
    filterInputWrappers.forEach((wrapper) => {
      const inputs = wrapper.querySelectorAll(".ag-input-field-input") as NodeListOf<HTMLInputElement>
      inputs.forEach((input) => {
        if (!input.hasAttribute("data-listener-attached")) {
          input.addEventListener("keydown", handleKeydown, true)
          input.setAttribute("data-listener-attached", "true")
        }
      })
    })
  }, [handleKeydown])

  const removeEventListeners = useCallback(() => {
    const filterInputWrappers = document.querySelectorAll(".ag-text-field-input-wrapper")
    filterInputWrappers.forEach((wrapper) => {
      const inputs = wrapper.querySelectorAll(".ag-input-field-input") as NodeListOf<HTMLInputElement>
      inputs.forEach((input) => {
        if (input.hasAttribute("data-listener-attached")) {
          input.removeEventListener("keydown", handleKeydown, true)
          input.removeAttribute("data-listener-attached")
        }
      })
    })
  }, [handleKeydown])

  useEffect(() => {
    const gridApi = gridRef.current?.api
    const onGridReady = () => {
      const filtersToolPanel = gridApi?.getToolPanelInstance("filters")
      if (filtersToolPanel) {
        const toolPanelContainer = document.querySelector(".ag-filter-toolpanel")
        if (toolPanelContainer) {
          const observer = new MutationObserver(() => {
            addEventListeners()
          })

          observer.observe(toolPanelContainer, { childList: true, subtree: true })
          addEventListeners()
          return () => {
            observer.disconnect()
            removeEventListeners()
          }
        }
      }
    }

    if (gridApi) {
      gridApi.addEventListener("firstDataRendered", onGridReady)
      return () => {
        gridApi.removeEventListener("firstDataRendered", onGridReady)
        removeEventListeners()
      }
    }
  }, [addEventListeners, gridRef.current?.api, removeEventListeners])

  useEffect(() => {
    return () => {
      removeEventListeners()
    }
  }, [removeEventListeners])

  useEffect(() => {
    const expandedId = gridRef.current?.api?.getState()?.rowGroupExpansion?.expandedRowGroupIds?.[0] || null
    const expandedNode = gridRef.current?.api?.getRowNode(expandedId || "")

    onRowExpanded?.(expandedRows, expandedNode?.data)
  }, [expandedRows])

  const sideBar = useMemo<SideBarDef | string | string[] | boolean | null>(() => {
    return {
      toolPanels: [
        ...(hasColumns
          ? [
              {
                id: "columns",
                labelDefault: "Columns",
                labelKey: "columns",
                iconKey: "columns",
                toolPanel: "agColumnsToolPanel",
                toolPanelParams: {
                  suppressRowGroups: true,
                  suppressValues: true,
                  suppressPivots: true,
                  suppressPivotMode: true,
                  suppressColumnFilter: true,
                  suppressColumnSelectAll: true,
                  suppressColumnExpandAll: true,
                },
              },
            ]
          : []),
        ...(hasFilters
          ? [
              {
                id: "filters",
                labelDefault: "Filters",
                labelKey: "filters",
                iconKey: "filter",
                toolPanel: "agFiltersToolPanel",
                toolPanelParams: {
                  suppressExpandAll: false,
                  suppressFilterSearch: true,
                },
              },
            ]
          : []),
      ],
      defaultToolPanel: undefined,
      hiddenByDefault: isSideBarHidden,
    }
  }, [isSideBarHidden])

  const debouncedSearchHandler = onSearchCallBack && debounce(onSearchCallBack, 500)

  useEffect(() => {
    if (showLoading) {
      setTimeout(() => {
        gridRef?.current?.api?.showLoadingOverlay()
      }, 0)
    } else if (!showLoading && rowData && rowData.length === 0) {
      setTimeout(() => {
        gridRef?.current?.api?.showNoRowsOverlay()
      }, 0)
    } else {
      setTimeout(() => {
        gridRef?.current?.api?.hideOverlay()
      }, 0)
    }
  }, [showLoading, rowData?.length])

  const updatedColumnDefinition = useMemo(() => {
    return [
      ...(isExpandable
        ? [
            {
              cellRenderer: "agGroupCellRenderer",
              maxWidth: 50,
              headerClass: "custom-ag-header",
              suppressColumnsToolPanel: true,
              suppressMovable: true,
              suppressMenu: true,
              lockPosition: true,
              pinned: "left",
            } as ColDef,
          ]
        : []),
      ...columnDefs,
      ...(popOverMenu() && popOverList
        ? [
            {
              headerName: "",
              maxWidth: 50,
              minWidth: 50,
              cellRendererParams: { popOverList, isDisabledPopOverList, disabledPopOverTooltip },
              cellRenderer: DataGridPopover,
              suppressColumnsToolPanel: true,
              headerClass: "custom-ag-header",
              cellStyle: { display: "flex", justifyContent: "right", alignItems: "center" },
              cellClass: "ag-select-text-cell",
              pinned: "right",
            } as ColDef,
          ]
        : []),
    ]
  }, [columnDefs, isExpandable, popOverList])

  // --------------------------- POLLING --------------------------------------------------------------------- //

  const handleStoreRefreshed = () => {
    pollTimerRef.current = undefined
    setServerSideRefreshing(false)
    setPolling(false)
  }

  useEffect(() => {
    if (!refreshInterval || rowModelType !== "serverSide") {
      clearTimeout(pollTimerRef.current)
      pollTimerRef.current = undefined
      setPolling(false)
      return
    }
    if (!polling && !pollTimerRef.current) {
      pollTimerRef.current = setTimeout(() => {
        setServerSideRefreshing(true)
        setPolling(true)
        gridRef.current?.api.refreshServerSide({ purge: false, route: [] })
      }, refreshInterval)
    }
  }, [polling, refreshInterval])
  // --------------------------- POLLING --------------------------------------------------------------------- //

  const onPaginationChanged = useCallback((params: PaginationChangedEvent<any>) => {
    setOffSet?.(params?.api?.paginationGetCurrentPage() * params?.api?.paginationGetPageSize())
    setPageSize?.(params?.api?.paginationGetPageSize(), params?.api?.paginationGetCurrentPage())
    if (rowModelType !== "clientSide") {
      setAllSelected?.((prev) => {
        if (prev === true) {
          const nodesToSelect: IRowNode[] = []
          params?.api?.forEachNode((node) => nodesToSelect.push(node))
          params?.api?.setNodesSelected({ nodes: nodesToSelect.filter((node) => node.isSelected), newValue: true })
          onRowSelected?.(params?.api?.getSelectedRows())
        }
        return prev
      })
    }
  }, [])

  // --------------------------- COLUMN STATE --------------------------------------------------------------------- //

  const STORAGE_KEY = "gridState-Identities"

  const [gridState, setGridState] = useState<GridStorageType>(() => {
    const savedState = localStorage.getItem(STORAGE_KEY)
    return savedState
      ? JSON.parse(savedState)
      : {
          columnState: null,
          columnGroupState: null,
          filterModel: null,
        }
  })

  const onGridColumnStateChange = useCallback((params: ColumnEvent) => {
    const columnState = params.api?.getColumnState()

    const savedStateString = localStorage.getItem(STORAGE_KEY)
    const savedState = savedStateString ? JSON.parse(savedStateString)?.columnState : columnState

    if (params?.context?.filterQuery) {
      setGridState((state) => {
        return {
          ...state,
          savedState,
        }
      })
      params.api?.applyColumnState({
        state: savedState,
        applyOrder: true,
      })
    } else {
      setGridState((state) => {
        return {
          ...state,
          columnState,
        }
      })
      params.api?.applyColumnState({
        state: columnState,
        applyOrder: true,
      })
    }
  }, [])

  const onFirstDataRendered = useCallback(
    ({ api }: FirstDataRenderedEvent) => {
      if (gridState?.columnState) {
        api?.applyColumnState({
          state: gridState.columnState,
          applyOrder: true,
        })
      }

      if (gridState?.columnGroupState) {
        api?.setColumnGroupState(gridState.columnGroupState)
      }

      if (gridState?.filterModel) {
        api?.setFilterModel(gridState.filterModel)
      }
    },
    [gridState],
  )

  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(gridState))
  }, [gridState])

  // --------------------------- COLUMN STATE --------------------------------------------------------------------- //

  return (
    <div className={clsx(classes.root, className)}>
      <>
        {showNavbars && (
          <DataGridNavigationBar
            onSearchCallBack={onSearchCallBack}
            debouncedSearchHandler={debouncedSearchHandler}
            showRowsSelected={showRowsSelected}
            numberOfRowsSelected={numberOfRowsSelected}
            label={label}
            onActionCallBack={onActionCallBack}
            actionText={actionText}
            onDragUpCallBack={onDragUpCallBack}
            dragUpText={dragUpText}
            onSaveCallBack={onSaveCallBack}
            saveText={saveText}
            onDragDownCallBack={onDragDownCallBack}
            dragDownText={dragDownText}
            onBulkActionPopover={onBulkActionPopover}
            serverSideRefreshing={serverSideRefreshing}
            onRefreshCallBack={onRefreshCallBack}
            refreshIntervalSeconds={refreshInterval / 1000}
            groupByCallBack={groupByCallBack}
          />
        )}
      </>
      {handleTimeFilter && timeFilterValue && (
        <>
          <ENDivider />
          <div className={classes.timefilterWrapper}>
            <ActivityLogsTimeFilter
              onChange={handleTimeFilter}
              value={timeFilterValue}
              isDisabled={disableTimeFilter}
            />
          </div>
        </>
      )}
      <div
        className={clsx(classes.agGridContainer, agContainerClassName)}
        style={containerHeight ? { height: containerHeight } : {}}
      >
        <AgGridReact
          getRowId={(params) => params.data.id} // Note: Always make sure that there is `id` as unique identifier in each row object
          defaultColDef={defaultColDef}
          ref={gridRef}
          className="ag-theme-alpine-dark"
          rowData={rowData}
          treeData={treeData}
          isServerSideGroup={serverSideGroupSelect ? (dataItem: any) => dataItem[serverSideGroupSelect] : undefined}
          getServerSideGroupKey={serverSideGroupKey ? (dataItem: any) => dataItem[serverSideGroupKey] : undefined}
          autoGroupColumnDef={autoGroupColumnDef}
          sortingOrder={sortingOrder}
          accentedSort={true}
          columnDefs={updatedColumnDefinition}
          onGridReady={onGridReady}
          masterDetail={isExpandable}
          detailCellRenderer={useCallback(
            (params: any) => {
              setExpandedRow(params.data.id)
              if (expandedRow && expandedRow !== params.data.id) {
                gridRef.current!.api.forEachNode((node: any) => {
                  if (node?.data?.id === expandedRow) {
                    node?.setExpanded(false)
                  }
                })
              }
              return (
                <div
                  className={clsx({
                    [classes.expandableComponentWrapperZeroPaddingBorder]: params.data.endSystems,
                    [classes.expandableComponentWrapper]: !params.data.endSystems,
                  })}
                >
                  {expandableRowComponent?.(params)}
                </div>
              )
            },
            [expandedRow, applyCollapsibleWrapper],
          )}
          detailRowAutoHeight={detailRowAutoHeight}
          suppressContextMenu={suppressContextMenu}
          rowSelection={isMultiSelect ? "multiple" : "single"}
          onSelectionChanged={onSelectionChanged}
          paginationAutoPageSize={paginationAutoPageSize}
          pagination={pagination}
          suppressRowTransform={true}
          sideBar={sideBar}
          paginationPageSize={10}
          paginationPageSizeSelector={[10, 20, 50]}
          tooltipShowDelay={0}
          loadingOverlayComponent={DataGridLoader}
          noRowsOverlayComponent={DataGridBlankSlate}
          noRowsOverlayComponentParams={noRowsOverlayComponentParams}
          suppressRowClickSelection
          isRowSelectable={isRowSelectable}
          suppressCellFocus={false}
          autoSizeStrategy={autoSizeStrategy}
          getRowHeight={getRowHeight}
          rowModelType={rowModelType}
          cacheBlockSize={cacheBlockSize}
          rowDragMultiRow={rowDragMultiRow}
          onRowDragEnd={onRowDragEnd}
          onRowDragEnter={onRowDragEnter}
          rowDragEntireRow={rowDragEntireRow}
          rowDragManaged={rowDragManaged}
          // enableCellTextSelection
          rowHeight={rowHeight}
          onPaginationChanged={onPaginationChanged}
          onStoreRefreshed={handleStoreRefreshed}
          headerHeight={headerHeight}
          onFilterChanged={onFilterChanged}
          suppressAnimationFrame={true}
          onRowGroupOpened={(params) => {
            if (params.expanded && expandedRows.indexOf(params.data.id) === -1) {
              setExpandedRows((selectedRowsState) => [...selectedRowsState, params.data.id])
            } else if (!params.expanded && expandedRows.indexOf(params.data.id) !== -1) {
              setExpandedRows((selectedRowsState) => selectedRowsState.filter((rowId) => rowId !== params.data.id))
              params.node?.setExpanded(false)
            }
          }}
          loadingCellRenderer={DataGridLoadingComponent}
          maxBlocksInCache={maxBlocksInCache}
          blockLoadDebounceMillis={blockLoadDebounceMillis}
          //COLUMN STATE
          onFirstDataRendered={onFirstDataRendered}
          onColumnMoved={onGridColumnStateChange}
        />
      </div>
    </div>
  )
}

export default forwardRef(DataGrid)

import { createUseStyles } from "react-jss"
import { TABS_BANNER_HEIGHT } from "src/constants/layout"
import {
  APP_LAYOUT_GRID_ROW_GAP,
  APP_MARGIN,
  CLOSED_DRAWER_WIDTH,
  DRAWER_WIDTH,
  TOP_NAV_HEIGHT,
} from "src/utils/constants"

type Props = {
  isSideBarOpened: boolean
  tabsBannerHeight: number
  upgradeBannerHeight: number
  appHeaderHeight: number
}

export const useAppContainerStyles = createUseStyles<string, Props>((theme) => ({
  root: {
    display: "grid",
    gridTemplateRows: `${TOP_NAV_HEIGHT}px auto`,
    gridTemplateAreas: `"TopNav TopNav""SideNav Content"`,
    rowGap: `${APP_LAYOUT_GRID_ROW_GAP}px`,
    columnGap: `${APP_LAYOUT_GRID_ROW_GAP}px`,
    // columnGap: ({ isSideBarOpened }) => (isSideBarOpened ? theme.spacing(4) : 0),
    padding: `${APP_MARGIN}px`,
    width: "100%",
    minWidth: 1366 - 12,
    maxWidth: 1920,
    [theme.breakpoints.up(1920 + APP_MARGIN * 2)]: {
      margin: "0 auto",
    },
  },
  rootWithClosedNavbar: {
    gridTemplateColumns: `${CLOSED_DRAWER_WIDTH}px calc(100% - ${CLOSED_DRAWER_WIDTH + APP_LAYOUT_GRID_ROW_GAP}px)`,
  },
  rootWithOpenedNavbar: {
    gridTemplateColumns: `${DRAWER_WIDTH}px calc(100% - ${DRAWER_WIDTH + APP_LAYOUT_GRID_ROW_GAP}px)`,
  },
  rootWithFixedHeight: {
    "@media screen and (min-height: 768px)": {
      height: `calc(100vh - ${APP_MARGIN * 2}px)`,
    },
  },
  container: {
    padding: 0,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    "&::-webkit-scrollbar": {
      width: 6,
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.color.grey[700],
    },
  },
  containerSpacing: {
    paddingLeft: 0,
    paddingRight: 0,
  },

  [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
    root: { minWidth: 1280 },
  },
  appHeader: {
    display: "flex",
    flexDirection: "column",
    top: 65 + APP_MARGIN + APP_LAYOUT_GRID_ROW_GAP,
    backgroundColor: theme.color.background.surfaceElevation0,
    paddingBottom: 16,
    zIndex: theme.zIndex.pageHeader,
  },
  appHeaderIsSticky: {
    position: "sticky",
    boxShadow: "0px 12px 10px -10px rgb(0 0 0 / 70%)",
    "&:before": {
      content: "''",
      height: 34,
      width: "100%",
      position: "absolute",
      top: -33,
      left: 0,
      backgroundColor: theme.color.background.surfaceElevation0,
    },
  },
  appHeaderIsHidden: {
    display: "none",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    // minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    backgroundColor: theme.color.background.surfaceElevation0,
    gridArea: "Content",
  },
  fixedHeightContent: {
    "@media (hover: hover)": {
      height: `calc(100vh - ${TOP_NAV_HEIGHT}px - ${APP_MARGIN * 2}px - ${APP_LAYOUT_GRID_ROW_GAP}px)`,
    },
    "@media (hover: hover) and (max-width: 1366px)": {
      height: `calc(100vh - ${TOP_NAV_HEIGHT}px - ${APP_MARGIN * 2}px - ${APP_LAYOUT_GRID_ROW_GAP}px - 16px)`, // subtract scrollbar height
    },
    "@media (hover: hover) and (max-height: 710px)": {
      height: "auto",
    },
  },
  bgGrey: {
    backgroundColor: theme.color.secondary[300],
  },
  pageHeaderMargin: {
    marginLeft: ({ isSideBarOpened }) => (isSideBarOpened ? `0px` : `${CLOSED_DRAWER_WIDTH + 20}px`),
  },
  navMarginSidebarOpen: {
    marginLeft: 0,
  },
  tabsContainer: {
    backgroundColor: theme.color.grey.A300,
    color: theme.color.grey[300],
    "& .MuiTab-textColorPrimary.Mui-selected": {
      color: theme.color.grey[900],
      backgroundColor: theme.color.neutralLight[16],
      fontWeight: 600,
    },
    "& .MuiTabs-flexContainer": {
      gap: 0,
    },
    "& .MuiTabs-indicator": {
      backgroundColor: theme.color.primary[600],
    },
    height: TABS_BANNER_HEIGHT,
  },
  tabPanel: {
    marginTop: 25,
    marginLeft: 20,
    marginRight: 20,
  },
  backdropContainer: {
    position: "fixed",
    zIndex: "calc(var(--en-z-index-top) + 2)",
  },
  breadcrumbContainer: {
    marginBottom: theme.spacing(1.5),
  },
  upgradeBanner: {
    position: "fixed",
    zIndex: theme.zIndex.upgradeBanner,
    left: "50%",
    transform: "translate(-50%)",
    top: 5,
  },
  loader: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    justifySelf: "center",
  },
  tabsView: {
    gap: 16,
  },
  licenseBannerContainerTab: {
    marginBottom: 16,
  },
  licenseBannerContainerNoTab: {
    marginTop: 16,
  },
}))
